import { ICustomizations } from "./providers/types";

export const origin =
  window != window.top && window.location.ancestorOrigins
    ? window.location.ancestorOrigins[
        window.location.ancestorOrigins.length - 1
      ]
    : String(document.location);
export const referrer = String(document.location);

export function createBidstreamMediaSrc(
  zid: number,
  friendly: string,
  random: number
) {
  let url = `https://ads.bidstreamserver.com/servlet/view/banner/javascript/zone?zid=${zid}&friendly=${friendly}&pid=1&refresh=60&refresh_limit=1&rmpid=true&random=${random}&encode=1`;
  url += `&origin=${encodeURIComponent(origin)}`;
  if (referrer) url += `&referrer=${referrer}`;
  return url;
}

export function appendScript(root: Element) {
  return function (src: string) {
    const script = document.createElement("script");
    script.src = src;
    root.appendChild(script);
  };
}

export function isMobile() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
}

export function getRandom() {
  return Math.floor(Math.random() * 1e8);
}

export function generateAds(theme: ICustomizations) {
  let appendScripts: [string, string | HTMLScriptElement][] = [];
  let headScripts: HTMLScriptElement[] = [];

  const mobile = isMobile();
  const withBidstream = (zid: number, width = 300, height = 250) => {
    if (!zid) return "";
    if (theme.adType === "BidstreamNew") {
      const id = `ad-${getRandom()}`;
      const ad = `<div id="${id}"></div>`
      const script = document.createElement("script");
      script.type = 'text/javascript'
      script.innerHTML = `var _avp = _avp || [];
      _avp.push({ tagid: '${id}', alias: '/', type: 'banner', zid: ${zid}, pid: 0, iframe: true, width: ${width}, height: ${height}, refresh: 60, refresh_limit: 1, rmpid: true, secure: true });`

      appendScripts.push([id, script]);
      return ad
    }
    const random = getRandom();
    const id = `friendly_${random}`;
    const ad = `<ins id="${id}"></ins>`;
    const script = createBidstreamMediaSrc(zid, id, random);
    appendScripts.push([id, script]);
    return ad;
  };
  const displayZids = mobile ? theme.mobileZids : theme.displayZids;
  let zids = [...displayZids]
  let adUnits = []
  try {
    adUnits = eval(theme.adUnits) || []
  } catch (e) {
    console.log('Error evaling adunits')
  }
  const googleTagIndexBySize: { [size: string]: number } = {}
  const googleUnitsBySize = adUnits.reduce((acc: any, unit: any) => {
    const isMobileUnit = unit.code.toLowerCase().indexOf('mobile') !== -1
    if (mobile && !isMobileUnit) return acc // dont use desktop on mobile
    if (!mobile && isMobileUnit) return acc // dont use mobile on desktop
    const size = unit.mediaTypes.banner.sizes.map((v: number[]) => v.join('x')).join(',')
    if (!acc[size]) acc[size] = []
    acc[size].push(unit)
    return acc
  }, {})
  const ads = {
    useBidstream: withBidstream,
    useBidstreamDisplay: () => {
      const zid = zids.shift()
      if (!zids.length) zids = [...displayZids]
      return withBidstream(zid || 0)
    },
    useZipRecruiter: (id: number, short = false) => {
      const zipRecruiterUri = 'https://bit.ly/33Rqhv3'
      const zipRecruiterImage = `https://cache.sellwild.com/ziprecruiter/${id}_v2.jpg`
      return `<a href="${zipRecruiterUri}" style="background-image: url(${zipRecruiterImage})" target="_blank" class="sellwild-zip-ad ${short ? "sellwild-listing-short" : ""}" rel="noopener noreferrer">
        <div class="listing-overlay"></div>
      </a>`
    },
    useBidstreamSkyscraper: () => withBidstream(theme.skyscraperZid),
    useTopBanner: () => withBidstream(theme.bannerZid, 728, 90),
    useBottomBanner: () => withBidstream(theme.bottomBannerZid, 728, 90),
    initialize: () => {
      switch (theme.adType) {
        case "MediaFuse": return ads.initializeMediaFuse()
        case "BidstreamNew": return ads.initializeBidstreamNew()
        case "GTag": return ads.initializeGoogleTag()
        case "Prebid": return ads.initializePrebid()
      }
    },
    initializeBidstreamNew: () => {
      var id = 'bidstream-new-script'
      if (document.getElementById(id)) return

      var script = document.createElement("script");
      script.id = id
      script.innerHTML = `var _avp = _avp || [];
      (function() {
        var s = document.createElement('script');
        s.type = 'text/javascript'; s.async = true; s.src = 'https://ads.bidstreamserver.com/js/libcode3.js';
        var x = document.getElementsByTagName('script')[0];
        x.parentNode.insertBefore(s, x);
      })();`
      headScripts.push(script);
    },
    initializeMediaFuse: () => {
      var id = 'media-fuse-script'
      if (document.getElementById(id)) return

      var gpt = document.createElement("script");
      gpt.src = "https://securepubads.g.doubleclick.net/tag/js/gpt.js";
      gpt.async = true;
      headScripts.push(gpt);

      var mediaFuse = document.createElement("script");
      mediaFuse.id = id
      mediaFuse.innerHTML = `!(function () { window.googletag = window.googletag || {}; window.vmpbjs = window.vmpbjs || {}; window.vpb = window.vpb || {}; vpb.fastLoad = true; googletag.cmd = googletag.cmd || []; vmpbjs.cmd = vmpbjs.cmd || []; var cmds = []; try{ cmds = googletag.cmd.slice(0); googletag.cmd.length = 0; }catch(e){} var ready = false; function exec(cb) { return cb.call(googletag); } var overriden = false; googletag.cmd.push(function () { overriden = true; googletag.cmd.unshift = function (cb) { if (ready) { return exec(cb); } cmds.unshift(cb); if (cb._startgpt) { ready = true; for (var k = 0; k < cmds.length; k++) { exec(cmds[k]); } } }; googletag.cmd.push = function (cb) { if (ready) { return exec(cb); } cmds.push(cb); }; }); if(!overriden){ googletag.cmd.push = function (cb) { cmds.push(cb); }; googletag.cmd.unshift = function (cb) { cmds.unshift(cb); if (cb._startgpt) { ready = true; if (googletag.apiReady) { cmds.forEach(function (cb) { googletag.cmd.push(cb); }); } else { googletag.cmd = cmds; } } }; } var dayMs = 36e5, cb = parseInt(Date.now() / dayMs), vpbSrc = '//player.ads.sellwild.com/prebidlink/' + cb + '/${theme.mediaFuseWrapper}', pbSrc = vpbSrc.replace('wrapper_hb', 'hb'), gptSrc = '//securepubads.g.doubleclick.net/tag/js/gpt.js', c = document.head || document.body || document.documentElement; function loadScript(src, cb) { var s = document.createElement('script'); s.src = src; s.defer=false; c.appendChild(s); s.onload = cb; s.onerror = function(){ var fn = function(){}; fn._startgpt = true; googletag.cmd.unshift(fn); }; return s; } loadScript(pbSrc); loadScript(gptSrc); loadScript(vpbSrc); })()`
      headScripts.push(mediaFuse);
    },
    initializeGoogleTag: () => {
      var gpt = document.createElement("script");
      gpt.src = "https://securepubads.g.doubleclick.net/tag/js/gpt.js";
      gpt.async = true;

      headScripts.push(gpt);
    },
    initializePrebid: () => {
      if (adUnits.length) {
        var gpt = document.createElement("script");
        gpt.src = "https://securepubads.g.doubleclick.net/tag/js/gpt.js";
        gpt.async = true;

        // var prebid = document.createElement("script");
        // prebid.src = "https://cache.sellwild.com/prebid4_30_0.js";
        // prebid.async = true;

        var googletag = document.createElement("script");

        googletag.innerHTML = `
        var PREBID_TIMEOUT = 1000;
        var FAILSAFE_TIMEOUT = 3000;
        
        var adUnits = ${theme.adUnits || "[]"};
        
        // ======== DO NOT EDIT BELOW THIS LINE =========== //
        var googletag = googletag || {};
        googletag.cmd = googletag.cmd || [];
        // googletag.cmd.push(function() {
        //     googletag.pubads().disableInitialLoad();
        // });
        
        // var pbjs = pbjs || {};
        // pbjs.que = pbjs.que || [];
        
        // pbjs.que.push(function() {
        //     pbjs.addAdUnits(adUnits);
        //     pbjs.requestBids({
        //         bidsBackHandler: initAdserver,
        //         timeout: PREBID_TIMEOUT
        //     });
        // });
        
        // function initAdserver() {
        //     if (pbjs.initAdserverSet) return;
        //     pbjs.initAdserverSet = true;
        //     googletag.cmd.push(function() {
        //         pbjs.setTargetingForGPTAsync && pbjs.setTargetingForGPTAsync();
        //         googletag.pubads().refresh();
        //     });
        // }
        
        // // in case PBJS doesn't load
        // setTimeout(function() {
        //     initAdserver();
        // }, FAILSAFE_TIMEOUT);
        `;

        headScripts.push(
            gpt, 
            // prebid, 
            googletag
        );
      }
    },
    useGoogleTag: (tagDesc: string, tagSize: [number, number], tag: string) => {
      var tagDescStr = JSON.stringify(tagDesc);
      var tagSizeStr = JSON.stringify(tagSize);
      if (!document.getElementById(tag)) {
        var googletag = document.createElement("script");
        var adscript = document.createElement("script");

        googletag.innerHTML = `
          googletag.cmd.push(function() {
              googletag.defineSlot(${tagDesc}, ${tagSizeStr}, '${tag}')
              .addService(googletag.pubads());
              googletag.pubads().enableSingleRequest();
              googletag.enableServices();
          });`;
  
        adscript.innerHTML = `
          window.googletag.cmd.push(function() { 
              window.googletag.display('${tag}'); 
          });`;
  
        headScripts.push(googletag);
        appendScripts.push([tag, adscript]);
      }


      return `<!-- ${tagDescStr} --><div id="${tag}" style='width: ${tagSize[0]}px; height: ${tagSize[1]}px;'></div>`;
    },
    useGoogleDisplay: (size: [number, number]) => {
      const sizeStr = size.join('x')
      if (!googleTagIndexBySize[sizeStr]) googleTagIndexBySize[sizeStr] = 0
      const tag = `google-tag-${sizeStr}-${googleTagIndexBySize[sizeStr]}`;
      if (!googleUnitsBySize[sizeStr] || !googleUnitsBySize[sizeStr][googleTagIndexBySize[sizeStr]]) return '';
      const item = googleUnitsBySize[sizeStr][googleTagIndexBySize[sizeStr]]
      googleTagIndexBySize[sizeStr]++
      return ads.useGoogleTag(`"${item.code}"`, size, tag)
    },
    useAudigent: () => {
      if (theme.audigent) {
        var audigentTag = document.createElement('script')
        audigentTag.innerHTML = `(function(w,d,t,u){
            var a=d.createElement(t);a.async=1;a.src=u+"?url="+encodeURIComponent(w.location.href)+"&ref="+encodeURIComponent(d.referrer);
            var s=d.getElementsByTagName(t)[0]; s.parentNode.insertBefore(a,s);
        })(window, document, 'script', 'https://a.ad.gt/api/v1/u/matches/359')`
        headScripts.push(audigentTag);
      }
    },
    appendScripts: (root: Element) => {
      try {
        headScripts.forEach((script) => {
          try {
            document.head.appendChild(script);
          } catch (e) {
            console.log(e);
          }
        });
        appendScripts.forEach(([id, src]) => {
          const element = root.querySelector(`#${id}`);
          if (element && typeof src === "string") {
            appendScript(element)(src);
          } else if (element) {
            try {
              element.appendChild(src as any);
            } catch (e) {
              console.log(e);
            }
          }
        });
        headScripts = [];
        appendScripts = [];
      } catch (e) {
        console.log(e);
      }
    },
  };
  return ads;
}
