import {
  main,
  generateLiteWidget,
  generateLiteListing,
  computeCols,
  observeNewElements,
  loadListingsSm,
  updateLiteListing,
  afterInteraction,
  createRemoteSession,
  sendListingAction,
  RemoteSession,
} from "./Widget";
import { generateAds } from "./AdStack";
import { ICustomizations } from "./providers/types";
import { getListingUrl } from "./providers/Customizations";
import { lotame } from "./Lotame";

const prevWidths: {[id: string]: number | null} = {};
main(() => observeNewElements("sellwild", runWidget));

(window as any)['runWidgets'] = () => observeNewElements("sellwild", runWidget)

function runWidget(root: Element, theme: ICustomizations, widgetId: string = '0') {
  const rect = root.parentElement && root.parentElement.getBoundingClientRect();
  if (rect && rect.width === prevWidths[widgetId]) return;
  prevWidths[widgetId] = rect && rect.width;
  const width = rect ? rect.width : theme.defaultWidth
  const cols = computeCols(
    rect && rect.width > 10 ? rect.width : theme.defaultWidth
  );
  const ads = generateAds(theme);
  ads.initialize();
  ads.useAudigent();
  const grid = generateGrid(theme, ads, cols);
  const topbanner = width > 728 ? ads.useGoogleDisplay([728,90]) || ads.useBidstream(theme.bannerZid,728,90) : "";
  const bottombanner = width > 728 ? ads.useGoogleDisplay([728,90]) || ads.useBidstream(theme.bottomBannerZid,728,90) : "";
  const session: RemoteSession = {};
  root.innerHTML = generateLiteWidget(
    theme,
    topbanner,
    grid,
    bottombanner,
    cols
  );
  ads.appendScripts(root);

  const appendListings = () => {
    return loadListingsSm(theme)
      .then(({ listings, config, widgetCacheVersionId }) => {
        session.widgetCacheVersionId = widgetCacheVersionId;

        const listingEls = root.querySelectorAll(
          '.sellwild-listing[data-loading="true"]'
        );

        for (const i in listingEls) {
          const listingEl = listingEls[i]
          if (!listingEl) continue
          const listing = listings[Number(i) % listings.length]
          if (!listing) continue
          const url = getListingUrl(listing.id, config, theme);
          updateLiteListing(listingEl, listing, url);
        }

        createRemoteSession(session);
      })
      .catch((e) => console.log("Listing error", e));
  };

  if (theme.optimizeFcp) {
    const removeListeners = afterInteraction(() => {
      removeListeners();
      appendListings();
    });
  } else {
    appendListings();
  }

  if (theme.lotame) {
    const lotameClientStart = Date.now();
    const lotameClient = lotame.initialize((profile) => {
      const profileId = profile.getProfileId();
      const panoramaId = profile.getPanoramaId();
      session.lotameId = profileId;
      session.panoramaId = panoramaId;
      createRemoteSession(session).catch(e => console.log('Create sess err', e));
    });
  
    lotameClient.cmd.push(() => {
      lotameClient.sync({});
    });
  
    if (theme.membershipType) {
      root.addEventListener("click", (ev: any) => {
        const listing = ev.target.closest(".sellwild-listing");
        if (!listing) return;
    
        const listingId = listing.getAttribute("data-id");
        if (!listingId) return;
    
        sendListingAction({
          trackingData: {
            userId: session.userId,
            panoramaId: session.panoramaId,
            lotameId: session.lotameId,
          },
          listingId,
          membershipType: theme.membershipType,
        }).catch(e => console.log('Send action err', e));
      });
    }
  }
}

function generateGrid(
  theme: ICustomizations,
  ads: ReturnType<typeof generateAds>,
  cols: number
) {
  switch (cols) {
    case 1:
      return renderItems(ads, theme.col1 || "M0M1M2");
    case 2:
      return renderItems(ads, theme.col2 || "D01D23");
    case 3:
      return renderItems(ads, theme.col3 || "012D3D");
    case 4:
      return renderItems(ads, theme.col4 || "0123D45D");
    case 5:
      return renderItems(ads, theme.col5 || "01234D567D");
    default:
      return renderItems(ads, theme.col6 || "012345D6789D");
  }
}

function renderItems(
  ads: ReturnType<typeof generateAds>,
  colString: string = ""
) {
  return decodeURI(colString)
    .split("")
    .map((type) => renderItem(ads, type))
    .join("");
}

function renderItem (ads: ReturnType<typeof generateAds>, type: string) {
    switch (type) {
        case 'G': return `<div class='sellwild-listing-ad'>${ads.useGoogleDisplay([300,250])}</div>`
        case 'M': return `<div class='sellwild-listing-ad'>${ads.useBidstreamDisplay()}</div>`
        case 'D': return `<div class='sellwild-listing-ad'>${ads.useBidstreamDisplay()}</div>`
        case 'S': return `<div class='sellwild-listing-ad-skyscraper'>${ads.useBidstreamSkyscraper()}</div>`
        case 'Z': return ads.useZipRecruiter(Math.floor(Math.random() * 12) + 1)
        case 'z': return ads.useZipRecruiter(Math.floor(Math.random() * 12) + 1, true)
        case '<': return '<div>'
        case '>': return '</div>'
        case 'i': return generateLiteListing('sellwild-listing-short')
        case 'l': return generateLiteListing('sellwild-listing-skinny')
        case 'L': return generateLiteListing()
        default: return ''
    }
}
